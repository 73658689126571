<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">about studio one.</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start About Area  -->
    <div class="about-area rn-section-gap bg_color--1">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../assets/images/home/programmer.gif"
            alt="About Images"
          />

          <v-row slot="about-extra" class="mt--30 mt_sm--10">
            <v-col sm="12" cols="12">
              <div class="about-us-list">
                <h3 class="heading-title">
                  Who we are
                </h3>
                <p>
                  Have you ever heard the phrase "if you want something done right, do it yourself"? That is the basic philosophy behind <i>studio one</i>. We've seen too many companies throw out large sums of money only to be disappointed by the results. We're here to do it right the first time.
                </p>
              </div>
            </v-col>
          </v-row>
        </About>
      </div>
    </div>
    <!-- Start About Area  -->

    <!-- Start Counterup Area  -->
    <div class="rn-counterup-area bg_color--1">
      <v-container>
        <CounterOne />
      </v-container>
    </div>
    <!-- End Counterup Area  -->

    <!-- Start List Style  -->
    <div class="rn-list-style rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-title text-center mt--20 mb--90">
              <h3 class="heading-title">what makes us different?</h3>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <!-- Start Single List Style  -->
          <v-col
            lg="4"
            md="4"
            sm="6"
            cols="12"
            v-for="(singleList, i) in listItemContent"
            :key="i"
            :class="singleList.class"
          >
            <div class="list-style-inner">
              <h3 class="fontWeight500">{{ singleList.title }}</h3>
              <p>
                {{ singleList.desc }}
              </p>
              <ul class="list-style--1">
                <li v-for="list in singleList.listContent" :key="list.id">
                  <i v-html="iconSvg(list.icon)"></i>
                  {{ list.desc }}
                </li>
              </ul>
            </div>
          </v-col>
          <!-- End Single List Style  -->
        </v-row>
      </v-container>
    </div>
    <!-- End List Style  -->

    <!-- Start team Area  -->
    <!-- <div class="rn-team-area rn-section-gap bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="section-title section-title--3 text-center mb--30">
              <h2 class="heading-title">meet the team.</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration.
              </p>
            </div>
          </v-col>
        </v-row> -->
        <!-- <TeamThree /> -->
      <!-- </v-container>
    </div> -->
    <!-- Start team Area  -->

    <!-- Start Testimonial Area  -->
    <div class="rn-testimonial-area rn-section-gap bg_color--1">
      <v-container>
        <Testimonial />
      </v-container>
    </div>
    <!-- Start Testimonial Area  -->

    <!-- Start Brand Area -->
    <!-- <div class="rn-brand-area brand-separation">
      <v-container>
        <Brand />
      </v-container>
    </div> -->
    <!-- End Brand Area -->
    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import About from "../components/about/About";
  import CounterOne from "../components/counter/CounterOne";
  import Tab from "../components/tabs/Tab"
  import TeamThree from "../components/team/TeamThree";
  import Testimonial from "../components/testimonial/Testimonial";
  import Brand from "../components/brand/Brand";
  import Footer from "../components/footer/Footer";
  import feather from "feather-icons";

  export default {
    components: {
      Header,
      About,
      CounterOne,
      Tab,
      TeamThree,
      Testimonial,
      Brand,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Home",
            to: "/",
            disabled: false,
          },
          {
            text: "About",
            to: "",
            disabled: true,
          },
        ],
        listItemContent: [
          {
            class: "mt_sm--30",
            title: "Mission",
            desc: `Nobody likes receiving low-quality work or being left with no response when dealing with a company. We're here to make it as easy for you as possible. We are committed to delivering high-quality work with the best possible service.`,
            listContent: [
              {
                icon: "check",
                desc: `High-quality product and services`,
              },              {
                icon: "check",
                desc: `All services tailored to your specific needs`,
              },
              {
                icon: "check",
                desc: `Clear and concise communication`,
              },
              {
                icon: "check",
                desc: `Hassle-free workflow`,
              },
            ],
          },
          {
            class: "mt_sm--30 mt_md--30",
            title: "Values",
            desc: `We strive to deliver the best service we can with less headache for you. We want to help you achieve your goals, and we take it seriously - we do everything like we do it for ourselves.`,
            listContent: [
              {
                icon: "check",
                desc: `Honesty`,
              },
              {
                icon: "check",
                desc: `Transparency`,
              },
              {
                icon: "check",
                desc: `Commitment to quality`,
              },
              {
                icon: "check",
                desc: `Doing it right the first time`,
              },
            ],
          },
          {
            class: "",
            title: "Technology",
            desc: `We pride ourselves in using cutting-edge technology. We stay up to date with the newest developments in the tech world, and try to take advantage of them.`,
            listContent: [
              {
                icon: "check",
                desc: `Modern stack for building web applications`,
              },
              {
                icon: "check",
                desc: `Industry-leading tools and equipment`,
              },
              {
                icon: "check",
                desc: `Powered by fast and reliable cloud solutions`,
              },
              {
                icon: "check",
                desc: `Industry professionals with real experience`,
              },
            ],
          },
        ],
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
